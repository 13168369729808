.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 250px;
  max-height: 300px;
  overflow: auto;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  text-align: center;
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
}

.dzu-dropzone::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dzu-dropzone::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
.dzu-dropzone::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
.dzu-dropzone::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 4px;
}

.dzu-dropzoneActive {
  background-color: #deebff;
  border-color: #2484ff;
}

.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #2484ff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #e6e6e6;
  color: #2484ff;
  border: none;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-previewContainer {
  padding: 20px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 0;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
}

.dzu-previewFileName {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dzu-previewImage {
  width: auto;
  max-height: 100px;
  max-width: 50%;
  border-radius: 4px;
}

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  margin: 10px 0;
  z-index: 1;
}

.dzu-submitButton {
  padding: 0 14px;
  min-height: 32px;
  background-color: #2484ff;
  border: none;
  border-radius: 4px;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  background-color: #e6e6e6;
  color: #333333;
  cursor: unset;
}
